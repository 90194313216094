import moment from "moment";

export const PENSION_STEP_SELECTION_ID = 0;
export const PENSION_STEP_INPUT_ID = 1;
export const PENSION_STEP_COMPLETE_ID = 2;

export const I18N_PF_TECHNICAL_BASICS = 'pensionFund.technicalBasics';
export const I18N_PF_CONTACTS = 'pensionFund.contacts';
export const I18N_PF_ORGANIZATION = 'pensionFund.organization';
export const I18N_PF_ADDITIONAL_QUESTIONS = 'pensionFund.additionalQuestions';
export const I18N_PF_CAP_WITHDRAWAL = 'pensionFund.capitalWithdrawal';
export const I18N_PF_RETIREMENT = 'pensionFund.retirement';
export const I18N_PF_OP_INVOICE = 'pensionFund.operatingInvoice';
export const I18N_PF_STRUCT_LOCATION = 'pensionFund.structuralLocation';
export const I18N_PF_ASSET_IN_OUT = 'pensionFund.assetInOut';
export const I18N_PF_ASSET_MANAGEMENT = 'pensionFund.assetManagement';
export const I18N_PF_INSURANCE = 'pensionFund.insurance';
export const I18N_PF_SAVINGS_PLAN = 'pensionFund.savingsPlan';
export const I18N_PF_CONDITIONS = 'pensionFund.conditions';
export const I18N_PF_MARKET_POSITIONS = 'pensionFund.marketPosition';
export const I18N_PF_DATA_PROTECTION = 'pensionFund.dataProtection';
export const I18N_PF_DATA_CONFIRMATION = 'pensionFund.dataConfirmation';
export const I18N_PF_COMMON = 'pensionFund.common';
export const I18N_PF_STATUS= 'pensionFund.status';

export const I18N_USERS = 'users';
export const I18N_REPORTS = 'reports';
export const I18N_REPORTS_SEARCH = 'reports.search';

export const PF_ORGANIZATION = 'organization';
export const PF_CONTACT = 'contacts';
export const PF_CONVERSION_RATE = 'conversionRate';
export const PF_ADDITIONAL_QUESTIONS = 'additionalQuestions';
export const PF_TECHNICAL_BASICS = 'technicalBasics';

export const CURRENT_YEAR = new Date().getFullYear();

export const INPUT_DATA_STATUS = {
    COMPLETED: 'COMPLETED',
    NOT_STARTED: 'NOT_STARTED',
    IN_PROGRESS: 'IN_PROGRESS'
};

export const SERVER_ENVIRONMENT = {
    PRODUCTION: 'bvg.transparens.ch',
    STAGING: 'transparens.switzerlandnorth.cloudapp.azure.com'
};

export const THEME = {
    PRODUCTION: 'black',
    STAGING: 'white',
    DEVELOPMENT: 'white'
};

export const HEATMAP = {
    OLIVE: { h: 63, s: 29, l: 60 },
    CHESTNUT: { h: 15, s: 30, l: 54 }
    // Dark variants
    // CHESTNUT: { h: 4, s: 47, l: 41 },
    // OLIVE: { h: 77, s: 37, l: 36 }
};

const now = moment();
export const DATES = {
   FIVE_YEARS_AGO: now.subtract(5, "years").get("years"),
   TEN_YEARS_AGO: now.subtract(10, "years").get("years"),
};


export const BRANCHES = [
    'Alle KMU',
    'Kleine KMU',
    'Mittlere KMU',
    'Grosse KMU',
    'Administration/Verwaltung',
    'Architektur',
    'Automobil',
    'Banken',
    'Bau',
    'Beratung/Consulting',
    'Bildung',
    'Chemie',
    'Dienstleistung',
    'Druck/Verpackung',
    'IT',
    'Einkauf',
    'Elektro/Elektronik',
    'Energie',
    'Finanz',
    'Forschung/Entwicklung',
    'Gesundheit/Soziales/Pflege',
    'Handel',
    'Handwerk',
    'Immobilien',
    'Industrie',
    'Internet',
    'Kunst/Kultur',
    'Marketing/Werbung/PR',
    'Marktforschung',
    'Maschinenbau',
    'Medien',
    'Medizin/Pharma',
    'Medizintechnik',
    'Nahrungsmittel/Landwirtschaft',
    'Personalwesen & -beschaffung',
    'Recht',
    'Seminare/Messen',
    'Sonstige Branchen',
    'Sport/Beauty',
    'Steuerberatung/Wirtschaftsprüfung',
    'Telekommunikation',
    'Textil',
    'Tourismus/Gastronomie',
    'Vereine',
    'Transport/Verkehr/Logistik',
    'Versicherung',
    'Öffentliche Verwaltung'
].sort()
export const ASSET_MANAGERS = [
    'CAEIS',
    'UBS',
    'Credit Suisse',
    'Swiss Life',
    'Allianz',
    'AXA',
    'Baloise',
    'Helvetia',
    'Mobiliar',
    'Zurich',
    'Pictet',
    'HSBC',
    'ZKB',
    'Kantonalbank',
    'Reichmuth',
    'Union Bancaire Privée',
    'Edmond de Rothschild',
    'Lombard Odier',
    'Mirabaud',
    'BNP Paribas',
    'Societe Generale',
    'GAM',
    'EFG',
    'LGT',
    'Partners Group',
    'MAN Investments',
    'Vontobel',
    'Julius Bär',
    'Schroders',
    'Albin Kistler',
    'Fisch',
    'Blackrock',
    'Vanguard',
    'Charles Schwab',
    'State Street',
    'Fidelity',
    'Aberdeen',
    'Amundi',
    'JP Morgan',
    'BNY Mellon',
    'PIMCO'
].sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
const GLOBAL_CUSTODIANS_LIST = [
    'UBS',
    'Credit Suisse',
    'ZKB',
    'Pictet',
    'Julius Bär',
    'Vontobel',
    'State Street',
    'JP Morgan',
    'Lombard Odier',
    'CAEIS',
    'Kantonalbank',
    'Safra Sarasin'
].sort();
export const GLOBAL_CUSTODIANS = ['Keiner', ...GLOBAL_CUSTODIANS_LIST]
const REINSURERS_LIST = [
    'AXA',
    'Allianz',
    'Basler',
    'Elips Life',
    'Helvetia',
    'Mobiliar',
    'PAX',
    'PKRück',
    'Swiss Life',
    'Zurich'
].sort();
export const REINSURERS = ['Keiner', ...REINSURERS_LIST]

export const MANAGEMENT_COMPENSATION = [
    { 'value': 1, 'label': 'reimburseExpenses' },
    { 'value': 2, 'label': 'fixedRemuneration' },
    { 'value': 3, 'label': 'fixedRenumerationWithVariable' },
    { 'value': 4, 'label': 'renumerationLinkedToTargets' },
    { 'value': 5, 'label': 'noRenumeration' }
];
export const LANGUAGES = [
    { 'value': 'de', 'flag': 'DE.png' },
    { 'value': 'fr', 'flag': 'FR.png' },
    // { 'value': 'en', 'flag': 'EN.png' },
];
export const WAITING_PERIOD = [
    '1 Monat',
    '3 Monate',
    '6 Monate',
    '12 Monate',
    '24 Monate'
];

export const YEARS = [];

for (let i = 0; i <= 10; i++) {
    const year = CURRENT_YEAR - i;
    YEARS.push(year);
}

export const ALLOWED_SECTIONS = [
    'organization',
    'marketPosition',
    'structuralLocation',
    'technicalBasics',
    'insurance',
    'retirement',
    'assetManagement',
    'additionalQuestions',
];

export const BVGValues = {
    2023: 1.00,
    2022: 1.00,
    2021: 1.00,
    2020: 1.00,
    2019: 1.00,
    2018: 1.00,
    2017: 1.00,
    2016: 1.25,
    2015: 1.75,
    2014: 1.75,
};
