import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import Login from 'views/pages/Login.jsx';
import SetPassword from 'views/pages/SetPassword.jsx';
import RequestPasswordReset from 'views/pages/RequestPasswordReset.jsx';
import { withLocalize} from 'react-localize-redux';
import {initialize} from "react-localize-redux";
import Footer from "components/Footer/Footer.jsx";
import {connect} from "react-redux";
import {loginSuccess} from "../../actions";
import SSO from "views/pages/SSO.jsx";


let json = {
    server: {
        userNotFound: ['User not found', 'DE: User not found'],
        invalidData: ['Wrong request', 'DE: Wrong request'],
        unauthorized: ['Not authorized', 'DE: Not authorized'],
        notAllowed: ['You session is not valid anymore', 'DE: You session is not valid anymore'],
        forbidden: ['User is not allowed to use this function', 'DE: User is not allowed to use this function'],
        passwordChanged: ['Password changed', 'DE: Password changed'],
        passwordNotMatched: [
            'Password does not match',
            'DE: Password does not match',
        ],
        userDetailChanged: ['User detail changed', 'DE: User detail changed'],
        maintenance: ['Server is under maintenance', 'DE: Server is under maintenance'],
        resetPasswordSent: [
            `If the email supplied is a valid user
            we have sent an email with instructions on resetting your password.
            These will be valid for the next 20 minutes`,
            `DE: If the email supplied is a valid user
            we have sent an email with instructions on resetting your password.
            These will be valid for the next 20 minutes`],
    },
    error: {
        title: ['Error occurred', 'DE: Error occurred'],
        content: ['Unauthorized access', 'DE: Unauthorized access'],
    },
    password: {
        tooShort: ['Password is too short (min 12)', 'mindestens acht Zeichen'],
        noNumber: ['Password should contain numbers', 'mindestens eine Ziffer'],
        noMixed: ['Password should contain upper and lower case', 'muss Groß - als auch Kleinbuchstaben enthalten'],
        noSpecial: ['Password should contain special characters', 'mindestens ein Sonderzeichen, z.B. ! @ # ? ]'],
        notDifferent: ['Password is the same as current password', 'Das Passwort entspricht dem aktuellen Passwort']
    },
};

class Pages extends React.Component {

    constructor(props) {
        super(props);
        const onMissingTranslation = ({translationId, languageCode}) => {
            return `Translation missing ${translationId} - ${languageCode}`;
        };
        const {initialize} = this.props;
        initialize({
            languages: [
                {name: 'English', code: 'en'},
                {name: 'German', code: 'de'},
            ],
            translation: json,
            options: {
                onMissingTranslation: onMissingTranslation,
                renderToStaticMarkup: false,
                defaultLanguage: 'de'
            },
        });
    }

    componentDidMount() {
    }

    render() {
        return (
            <>
                <div className="wrapper wrapper-full-page" ref="fullPages">
                    <div className="full-page section-image">
                        <Switch>
                            <Route
                                path='/auth/login'
                                component={Login}
                            />
                            <Route
                                path='/auth/setPassword/:token'
                                component={SetPassword}
                            />
                            <Route
                                path='/auth/sign/:token'
                                component={SSO}
                            />
                            <Route
                                path='/auth/requestPasswordReset'
                                component={RequestPasswordReset}
                            />
                            <Redirect from='*' to='/auth/login'/>
                        </Switch>
                        <Footer fluid/>
                    </div>
                </div>
            </>
        );
    }
}
const mapDispatchToProps = dispatch => ({
    login: () =>
        dispatch(loginSuccess()),
    initialize: (payload) => dispatch(initialize(payload))
});

export default connect(null, mapDispatchToProps)(
    withLocalize(Pages)
);
