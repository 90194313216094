import React from 'react';
import {connect} from 'react-redux';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import PropTypes from 'prop-types';
import 'redux-notifications/lib/styles.css';
import {
    UncontrolledAlert,
} from "reactstrap";
import {Translate} from "react-localize-redux";

// This checks to see if object is immutable and properly access it
const getter = (obj, propName) => (obj.get ? obj.get(propName) : obj[propName]);

const Notifs = (props) => {
    const {
        notifications,
        componentClassName,
        transitionEnterTimeout,
        transitionLeaveTimeout,
    } = props;

    const renderedNotifications = notifications.map((notification, index) => {
        return (
            <UncontrolledAlert key={index} color={getter(notification, 'level')} fade={false}>
                <span>
                     <Translate id={getter(notification, 'message')}/>
                </span>
            </UncontrolledAlert>
        );
    });

    return (
        <div className='notification-container'>
            <CSSTransitionGroup
                transitionName={`${componentClassName}-transition`}
                transitionEnterTimeout={transitionEnterTimeout}
                transitionLeaveTimeout={transitionLeaveTimeout}
            >
                {renderedNotifications}
            </CSSTransitionGroup>
        </div>
    );
};

Notifs.defaultProps = {
    className: null,
    componentClassName: 'notif',
    CustomComponent: null,
    transitionEnterTimeout: 600,
    transitionLeaveTimeout: 600,
};

Notifs.propTypes = {
    notifications: PropTypes.array.isRequired,
    className: PropTypes.string,
    CustomComponent: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.node,
        PropTypes.element,
    ]),
    componentClassName: PropTypes.string,
    transitionEnterTimeout: PropTypes.number,
    transitionLeaveTimeout: PropTypes.number,
};

function mapStateToProps(state) {
    return {notifications: state.get ? state.get('notifs') : state.notifs};
}

export default connect(mapStateToProps)(Notifs);
