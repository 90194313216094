import {
  PROVIDERS_LOAD_RESP,
  PROVIDERS_LOAD_REQ,
  PROVIDER_ADD_REQ,
  PROVIDERS_ROW_EXPANDED,
  PROVIDER_ADD_RESP,
  PROVIDERS_ACTIVE_LOAD_RESP,
} from '../../actions/actionTypes';

let initialState = {
  providers: [],
  activeProviders: [],
  providersLoading: false,
  expandedRowIds: [],
  providerNames: new Set([]),
  providerNameById: {},
  addingProvider: false,
};

export const providersReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROVIDERS_LOAD_REQ:
      return {
        ...state,
        providers: [],
        providersLoading: true,
      };
    case PROVIDERS_LOAD_RESP:
      let names = new Set(action.providers.map(it => it.providerName));
      const result = providersById(action.providers);
      return {
        ...state,
        providersLoading: false,
        providers: action.providers,
        providerNames: names,
        providerNameById: result
      };
    case PROVIDERS_ACTIVE_LOAD_RESP:
      return {
        ...state,
        providerNames: new Set(action.activeProviders.map(it => it.providerName)),
        providerNameById: providersById(action.activeProviders),
        activeProviders: action.activeProviders,
      };
    case PROVIDERS_ROW_EXPANDED:
      // let expandedRowIds = action.orgUnits.map(it => it._id);
      return {
        ...state,
        expandedRowIds: action.expandedProviderIds,
        providersLoading: false,
      };
    case PROVIDER_ADD_REQ:
      return {
        ...state,
        addingProvider: true,
      };
    case PROVIDER_ADD_RESP:
      return {
        ...state,
        addingProvider: false,
      };
    default:
      return state;
  }
};

const providersById = (providers) => {
  return providers.reduce(function(map, obj) {
    map[obj._id] = obj.providerName;
    return map;
  }, {});
};
