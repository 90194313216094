import React from 'react';
import { UncontrolledAlert } from 'reactstrap';
import { Translate } from 'react-localize-redux';

const MessageNotification = prop => {
  if (prop.messageKey) {
    if (prop.messageKey.type === 'error') {
      return (
        <UncontrolledAlert color="danger">
          <span>
            <Translate id={prop.messageKey.msg} />
          </span>
        </UncontrolledAlert>
      );
    } else {
      return (
        <UncontrolledAlert color="info">
          <span>
            <Translate id={prop.messageKey.msg} />
          </span>
        </UncontrolledAlert>
      );
    }
  } else {
    return <span />;
  }
};

export default MessageNotification;
