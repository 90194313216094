import React, {useState} from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import IdleTimer from "react-idle-timer";
import {logout, refreshLoginRequest} from "../../actions";

import {useDispatch} from "react-redux";
import DialogActions from "@material-ui/core/DialogActions";
import {Button} from "reactstrap";
import CountdownLogout from "./CountdownLogout";

const IdleNotification = () => {
    const [openIdleDialog, setOpenIdleDialog] = useState(false);
    const dispatch = useDispatch();

    const onAction = (e) => {
    };
    const onActive = (e) => {
    };

    const onIdle = (e) => {
        setOpenIdleDialog(true);
    };

    const onIdleLogout = (e) => {
        dispatch(logout())
    };

    const refresh = () => {
        setOpenIdleDialog(false);
        dispatch(refreshLoginRequest(sessionStorage.getItem('token')));
    };

    return (
        <>
            <IdleTimer
                ref={ref => {
                    // idleTimer = ref
                }}
                element={document}
                events={['mousedown', 'keydown']}
                onActive={onActive}
                onIdle={onIdle}
                onAction={onAction}
                debounce={250}
                timeout={1000 * 60 * 120}/>
            <Dialog aria-labelledby="simple-dialog-title"
                    open={openIdleDialog} maxWidth={'sm'}>
                <DialogTitle id="simple-dialog-title">Session Expire Warning</DialogTitle>
                <DialogContent style={{backgroundColor: '#f4f3ef'}} dividers={true}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        tabIndex={-1}
                    >
                        {openIdleDialog && <CountdownLogout />}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained"
                            className={'btn-round'}
                            color="info"
                            onClick={refresh}
                            type="submit">
                        Stay
                    </Button>
                    <Button variant="contained"
                            className={'btn-round'}
                            color="danger"
                            onClick={onIdleLogout}
                            type="submit">
                        Exit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
};

export default IdleNotification;