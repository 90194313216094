import { get } from 'lodash';
import {getTranslate} from "react-localize-redux";

const getAccessRoles = state => get(state, 'accessRolesReducer.accessRoles', []);
const getIsLoggedIn = state => get(state, 'auth.logged', false);

const getProviderOnly = state => get(state, 'context.context.providerOnly', false);
const getContext = state => get(state, 'context.context', {});
const getActiveLanguage = state => state.localize.languages.find(lang => lang.active);

//REPORTS
const getReportPeriod = state => get(state, 'reports.reportPeriod');
const getDisplayedRows = state => get(state, 'reports.displayedRows', 10);
const getConversionRateRanking = state => get(state, 'reports.conversionRateRanking', 'desc');
const getFixedProviderList = state => get(state, 'reports.fixedProviderList');
const getAnonymiseProviders = state => get(state, 'reports.anonymiseProviders');

//PENSION FUND
const getPensionFundPeriodNameById = state => get(state, 'pensionFundReducer.pensionFundPeriodNameById', {});
const getPensionFundPeriodIdByName = state => get(state, 'pensionFundReducer.pensionFundPeriodIdByName', {});
const getPensionFundDataProvider = state => get(state, 'pensionFundReducer.pensionFundDataProvider', {});
const getPensionFundActiveStep = state => get(state, 'pensionFundReducer.activeStep', 0);
const getPensionFundLadingNext = state => get(state, 'pensionFundReducer.loadingNext', false);
const getPensionFundDataList = state => get(state, 'pensionFundReducer.pensionFundDataList', []);
const getPensionFundPeriodsWithStatus = state => get(state, 'pensionFundReducer.pensionFundPeriodsWithStatus', []);
// const getPensionFundSearchCriteria = state => get(state, 'pensionFundReducer.searchCriteria', {});
const getPensionFundSearchResult = state => get(state, 'pensionFundReducer.searchResultProviderList', []);

//ADMIN LOGs
const getAdminLogs = state => get(state, 'adminLogReducer.adminLogs', []);
const getAdminLogsLoading = state => get(state, 'adminLogReducer.adminLogsLoading', []);

//PROVIDERS
const getProviderNameById = state => get(state, 'providersReducer.providerNameById', {});
const getProviders = state => get(state, 'providersReducer.providers', {});
const getActiveProviders = state => get(state, 'providersReducer.activeProviders', {});

//STATISTICS
const getPFDataStatsByPeriod = state => get(state, 'statisticsReducer.pensionDataStatsByPeriods', []);

//USERS
const newUserDialogOpen = state => get(state, 'usersReducer.openNewUserDialog', false);
const isPensionFundUser = state => get(state, 'usersReducer.isPensionFundUser', false);
const getUsers = state => get(state, 'usersReducer.users', []);
const getProviderUsers = state => get(state, 'usersReducer.providerUsers', []);
const getAssignedUsers = state => get(state, 'usersReducer.transferList.assignedUsers', []);
const getUnassignedUsers = state => get(state, 'usersReducer.transferList.unassignedUsers', []);
const getSelectedProviderId = state => get(state, 'usersReducer.transferList.providerId', '');
const getTranslator = state => getTranslate(state.localize);


const getRoutes = state => get(state,"routesProvider.routes", []);


export default {
    getRoutes,
    getTranslator,
    getPensionFundLadingNext,
    getPensionFundActiveStep,
    getPensionFundDataProvider,
    isPensionFundUser,
    newUserDialogOpen,
    getAccessRoles,
    getUsers,
    getProviderUsers,
    getIsLoggedIn,
    getAssignedUsers,
    getUnassignedUsers,
    getSelectedProviderId,
    getProviderOnly,
    getContext,
    getPFDataStatsByPeriod,
    getProviderNameById,
    getProviders,
    getPensionFundPeriodNameById,
    getPensionFundPeriodIdByName,
    getPensionFundDataList,
    getAdminLogs,
    getAdminLogsLoading,
    getActiveLanguage,
    getPensionFundPeriodsWithStatus,
    getActiveProviders,
    getDisplayedRows,
    getConversionRateRanking,
    getFixedProviderList,
    getAnonymiseProviders,
    getReportPeriod,
    getPensionFundSearchResult
}