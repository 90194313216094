import React from "react";
import {
    Container,
    Col,
    Row,
} from "reactstrap";
import { Redirect, withRouter } from "react-router";
import { connect } from "react-redux";
import {refreshLoginRequest} from '../../actions/index';
import { withLocalize } from 'react-localize-redux';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({});

class SetPassword extends React.Component {

    componentDidMount() {
        const {doRefreshLogin} = this.props;
        document.body.classList.toggle("login-page");
        doRefreshLogin(this.props.match.params.token);
    }

    componentWillUnmount() {
        document.body.classList.toggle("login-page");
    }

    render() {
        if (this.props.logged) {
            return <Redirect to="/admin/pensionSearch" />
        } else {
            return (
                <div className="login-page">
                    <Container>
                        <Row>
                            <Col className="ml-auto mr-auto" lg="4" md="6">
                                {this.props.logged && "Logged in"}
                            </Col>
                        </Row>
                    </Container>
                    <div
                        className="full-page-background"
                        style={{
                            backgroundImage: `url(${require("assets/img/bg/trsnp.jpg")})`
                        }}
                    />
                </div>
            );
        }
    }
}

const mapStateToProps = (state = []) => ({
    logged: state.auth.logged
});

const mapDispatchToProps = dispatch => ({
    doRefreshLogin: (token) =>
        dispatch(refreshLoginRequest(token, false))
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(
        withLocalize(
            withStyles(styles)(SetPassword)
        )
    )
);

