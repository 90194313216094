import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Form,
    Container,
    Col,
    Row,
    Alert
} from "reactstrap";
import { Redirect, withRouter } from "react-router";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField/TextField";
import { logout } from '../../actions/index';
import { resetForgottenPassword, checkVerificationToken } from '../../actions/index';
import MessageNotification from "../../components/common/MessageNotification";
import { Translate, withLocalize } from 'react-localize-redux';
import { passwordStrength } from '../../components/common/PasswordStrength';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({});

class SetPassword extends React.Component {

    componentDidMount() {
        document.body.classList.toggle("login-page");
    }

    componentWillUnmount() {
        document.body.classList.toggle("login-page");
    }

    constructor (props) {
        super(props);
        this.state = {
            password: '',
            confirmPassword: '',
            passwordWasReset: false,
            pwdDoesNotMatch: false,
            pwdErrors: []
        };
        props.doCheckVerificationToken(props.match.params.token)
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.changePwdButtonDisabled = this.changePwdButtonDisabled.bind(this);
        this.checkPwd = this.checkPwd.bind(this);
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value }, this.checkPwd);
    }

    checkPwd() {
        this.setState({ pwdErrors: passwordStrength(this.state.password) })
        if ((this.state.password === this.state.confirmPassword) || this.state.confirmPassword === '') {
            this.setState({ pwdDoesNotMatch: false })
        } else {
            this.setState({ pwdDoesNotMatch: true })
        }
    }

    changePwdButtonDisabled() {
        const { password, confirmPassword, pwdDoesNotMatch } = this.state;
        return password === '' || confirmPassword === '' || pwdDoesNotMatch;
    }

    onSubmit(e) {
        e.preventDefault();
        this.props.doResetForgottenPassword(
            this.props.match.params.token,
            this.state.password,
            this.state.confirmPassword
        );
        this.setState({ passwordWasReset: true });
    }

    render() {
        const { logout, errorMsg } = this.props;

        if (sessionStorage.getItem('token') === null && this.props.logged) {
            logout();
        }
        if (this.state.passwordWasReset) {
            return <Redirect to="/app/login" />
        } 
        if (this.props.logged && sessionStorage.getItem("token") !== null) {
            return <Redirect to="/admin/dataView" />
        } else {
            return (
                <div className="login-page">
                    <Container>
                        <Row>
                            <Col className="ml-auto mr-auto" lg="4" md="6">
                                {this.props.resetTokenVerified &&
                                    <Form onSubmit={this.onSubmit} className="form"  >
                                        <Card className="card-login">
                                            <CardHeader>
                                                <CardHeader>
                                                    <h3 className="header text-center">Set Password</h3>
                                                </CardHeader>
                                            </CardHeader>
                                            <CardBody className="text-center">
                                                {this.state.pwdDoesNotMatch && <Alert color="danger">
                                                    <Translate id={'server.passwordNotMatched'} />
                                                </Alert>}
                                                <MessageNotification messageKey={errorMsg} color='info' />
                                                {this.state.pwdErrors.map((item, index) => 
                                                    <Alert key={index} color="danger">
                                                        <Translate id={item} />
                                                    </Alert>                                               
                                                )}
                                                <TextField
                                                    value={this.state.password}
                                                    name="password"
                                                    type="password"
                                                    label="Password"
                                                    onChange={this.onChange}
                                                    margin="normal"
                                                    fullWidth
                                                />
                                                <br />
                                                <TextField
                                                    value={this.state.confirmPassword}
                                                    name="confirmPassword"
                                                    type="password"
                                                    label="Confirm Password"
                                                    onChange={this.onChange}
                                                    margin="normal"
                                                    fullWidth
                                                />
                                                <br />
                                            </CardBody>
                                            <CardFooter>
                                                <Button
                                                    block
                                                    className="btn-round mb-3"
                                                    color="info"
                                                    type="submit"
                                                    disabled={this.changePwdButtonDisabled()}
                                                    onClick={this.onSubmit}
                                                >
                                                    Save
                                                </Button>
                                            </CardFooter>
                                        </Card>
                                    </Form>
                                }
                                {!this.props.resetTokenVerified &&
                                    <Card className="card-login">
                                        <CardHeader>
                                            <CardHeader>
                                                <h3 className="header text-center">Invalid Request</h3>
                                            </CardHeader>
                                        </CardHeader>
                                        <CardBody className="text-justify">
                                        Password reset request is invalid. It may have expired. Please note that requests must be completed within 20 mins of receiving email.
                                        <div className="text-center" class="m-3">
                                        <Link to="/auth/requestPasswordReset" >
                                            Request another password reset
                                        </Link>
                                        </div>
                                        </CardBody>
                                    </Card>
                                }
                            </Col>
                        </Row>
                    </Container>
                    <div
                        className="full-page-background"
                        style={{
                            backgroundImage: `url(${require("assets/img/bg/trsnp.jpg")})`
                        }}
                    />
                </div>
            );
        }
    }
}

const mapStateToProps = (state = []) => ({
    resetTokenVerified: state.auth.resetTokenVerified,
    logged: state.auth.logged
});

const mapDispatchToProps = dispatch => ({
    doResetForgottenPassword: (token, password, confirmPassword) =>
        dispatch(resetForgottenPassword(token, password, confirmPassword)),
    doCheckVerificationToken: (token) =>
        dispatch(checkVerificationToken(token)),
    logout: () => dispatch(logout())
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(
        withLocalize(
            withStyles(styles)(SetPassword)
        )
    )
);

