import React, {Suspense, useEffect} from 'react';
import {Route} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import ErrorBoundary from 'components/ErrorBoudary';
import {logout} from 'actions';
import Selectors from 'selectors/selectors';
import {refreshLoginRequest} from "./actions";
import Redirect from "react-router/Redirect";

const AuthorizedRoute = (props) => {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(Selectors.getIsLoggedIn);

    useEffect(() => {
        let isNotLogged = sessionStorage.getItem('token') === null && isLoggedIn;
        if (isNotLogged) {
            dispatch(logout());
        }

        if (sessionStorage.getItem('token') !== null && !isLoggedIn) {
            dispatch(refreshLoginRequest(sessionStorage.getItem('token')));
        }
    }, [isLoggedIn]);

    const lazyLoaded = () => {
        if (props.compPath) {
            const Comp = React.lazy(() => import(`./pages/${props.compPath}`));
            return (
                <ErrorBoundary>
                    <Suspense fallback={<div>Loading...</div>}>
                        <Comp {...props} />
                    </Suspense>
                </ErrorBoundary>
            );
        }
    };

    return (
        <Route
            path={props.path}
            render={() => {
                return isLoggedIn ? lazyLoaded() : <Redirect to="/auth/login"/>;
            }}
        />
    );
};

export default AuthorizedRoute;
